/**
 * @file ChampionStatistics.tsx
 * This file defines the ChampionStatistics component which displays the statistics of a champion in a specific position.
 * It fetches the best build for the champion in the given position and displays it.
 * It also handles the loading state and errors when there is not enough data to generate a build.
 */

import {Champion} from "../../api/Types";
import * as React from 'react';
import {Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {BestBuild, getBestBuild, ItemWinRate} from "../../api/Champions";
import {ItemsContext, ItemsProvider} from "../../context/Items";
import {ItemData} from "../../api/Items";
import {ImageIcon} from "../utils/Image";
import {FormatWinRateColor} from "../Rates/WinRate";
import {ShowNotEnoughDataError} from "../utils/Error";
import {ShowCircularProgress} from "../utils/Progress";
import {DisplayRateNodeWithColor} from "../Rates/RateList";

/**
 * @interface ChampionStatisticsProps
 * @property {Champion} champion - The champion for which the statistics are to be displayed.
 * @property {string} position - The position in which the champion is played.
 */

interface ChampionStatisticsProps {
    champion: Champion;
    position: string;
}

/**
 * @interface ChampionStatisticsDisplayProps
 * @extends ChampionStatisticsProps
 * @property {BestBuild | undefined} build - The best build for the champion in the given position.
 */

interface ChampionStatisticsDisplayProps extends ChampionStatisticsProps {
    build: BestBuild | undefined;
    ref: React.ForwardedRef<any>;
}


/**
 * @function GenerateItemDisplay
 * @param {ItemData} item - The item data which includes the item's name and icon URL.
 * @param {ItemWinRate} itemWinRate - The win rate data of the item which includes the win rate and total games.
 * @returns {React.ReactNode} - A React Node that displays the item's details.
 */

function GenerateItemDisplay(item: ItemData, itemWinRate: ItemWinRate): React.ReactNode {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'left',
            padding: '0.5rem',

        }}>
            <ImageIcon iconPath={item.iconURL} size={60}/>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'left',
                marginLeft: '1rem',
                marginRight: '1rem',

            }}>
                <Typography variant="h6" component="div">
                    {item.name}
                </Typography>
                <Typography sx={{mb: 1.5}} color="text.secondary">
                    Win rate
                    of {DisplayRateNodeWithColor(itemWinRate.winRate, FormatWinRateColor)} over {itemWinRate.totalGames} games
                </Typography>
            </div>
        </div>
    )
}

/**
 * @function CreatePaperForItemCategory
 * @param {ItemWinRate[] | undefined} items - The items to be displayed.
 * @param {string} categoryLabel - The label for the category of items.
 * @returns {React.ReactNode} - A React Node that displays the items in a Paper component.
 */
function CreatePaperForItemCategory(items: ItemWinRate[] | undefined, categoryLabel: string): React.ReactNode {
    const {getItemById} = React.useContext(ItemsContext);

    return (
        <Paper key={categoryLabel} sx={{
            margin: '0.5rem',
        }}>
            <Typography variant="h4" textAlign="center" gutterBottom>{categoryLabel}</Typography>
            <Divider variant="middle" orientation="horizontal" sx={{backgroundColor: "black"}}/>
            <Stack
                marginTop={'1rem'}
                paddingBottom={'1rem'}
                direction="column"
                justifyContent="center"
                alignItems="center"
                useFlexGap
            >
                {items && items.length > 0 ? items.map((itemData: ItemWinRate) => {
                    const item: ItemData | undefined = getItemById(itemData.itemID);
                    if (!item) {
                        console.warn("Item not found for itemID: ", itemData.itemID);
                        return null;
                    }
                    return GenerateItemDisplay(item, itemData);
                }) : <ShowNotEnoughDataError/>
                }
            </Stack>
        </Paper>
    )
}

/**
 * @function DisplayChampionStatistics
 * @param {ChampionStatisticsDisplayProps} props - The properties for the component.
 * @returns {React.FC} - A React functional component that displays the champion's statistics.
 */

const DisplayChampionStatistics = React.forwardRef<HTMLDivElement, ChampionStatisticsDisplayProps>(
    ({champion, position, build}, ref) => {
        React.useEffect(() => {
            document.title = "League Item Recommender — " + champion.name + " " + position;
        }, [champion.name, position]);
        return (
            <Grid ref={ref} container spacing={4} sx={{flexGrow: 1}} direction="row">
                <Grid xs={12} item>
                    <Paper sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0.5rem',

                    }}>
                        <img
                            loading="lazy"
                            width="40"
                            srcSet={`${champion.iconURL}`}
                            src={`${champion.iconURL}`}
                            alt={champion.name}
                        />
                        <h1 style={{
                            marginLeft: '1rem',
                            marginRight: '1rem',
                        }}> {champion.name} — {position} </h1>
                    </Paper>
                </Grid>
                <Grid container direction="row" item>
                    <Grid xs={6} item>
                        {CreatePaperForItemCategory(build ? build.items : undefined, "Main items")}
                    </Grid>
                    <Grid container direction="column" xs={6} item>
                        <Grid>
                            {CreatePaperForItemCategory(build ? build.starter : undefined, "Starting items")}
                        </Grid>
                        <Grid>
                            {CreatePaperForItemCategory(build ? build.boots : undefined, "Boots")}
                        </Grid>
                        <Grid>
                            {CreatePaperForItemCategory(build ? build.trinket : undefined, "Trinket")}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
);

/**
 * @function ChampionStatistics
 * @param {ChampionStatisticsProps} props - The properties for the component.
 * @returns {React.FC} - A React functional component that fetches the best build for the champion in the given position and displays it.
 */

export const ChampionStatistics: React.FC<ChampionStatisticsProps> = ({champion, position}) => {
    const [loading, setLoading] = React.useState(true);
    const [build, setBuild] = React.useState<BestBuild | undefined>(undefined);
    const loadingRef = React.useRef<HTMLDivElement>(null);
    const buildShownRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        setLoading(true);
        getBestBuild(champion.id, position).then((build: BestBuild | undefined) => {
            setBuild(build);
            setLoading(false);
        })
    }, [champion.id, position]);

    React.useEffect(() => {
        if (loading && loadingRef.current) {
            loadingRef.current.scrollIntoView({behavior: 'smooth'});
        } else if (!loading && buildShownRef.current) {
            buildShownRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [loading]);

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            borderRadius: 10,
            padding: '5rem',
            overflow: 'hidden'
        }}>
            <ItemsProvider>
                {loading ? <ShowCircularProgress ref={loadingRef}/> :
                    <DisplayChampionStatistics ref={buildShownRef} champion={champion} build={build}
                                               position={position}/>}
            </ItemsProvider>
        </Box>
    )
}