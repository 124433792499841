import {default as Default} from './default.webp';
import {default as Level_30} from './Level_30.webp';
import {default as Level_50} from './Level_50.webp';
import {default as Level_75} from './Level_75.webp';
import {default as Level_100} from './Level_100.webp';
import {default as Level_125} from './Level_125.webp';
import {default as Level_150} from './Level_150.webp';
import {default as Level_175} from './Level_175.webp';
import {default as Level_200} from './Level_200.webp';
import {default as Level_225} from './Level_225.webp';
import {default as Level_250} from './Level_250.webp';
import {default as Level_275} from './Level_275.webp';
import {default as Level_300} from './Level_300.webp';
import {default as Level_325} from './Level_325.webp';
import {default as Level_350} from './Level_350.webp';
import {default as Level_375} from './Level_375.webp';
import {default as Level_400} from './Level_400.webp';
import {default as Level_425} from './Level_425.webp';
import {default as Level_450} from './Level_450.webp';
import {default as Level_475} from './Level_475.webp';
import {default as Level_500} from './Level_500.webp';

// Helper function to get the appropriate border based on level
function getBorderForLevel(level: number): string {
    if (level < 30) {
        return Default;
    } else if (level >= 30 && level < 50) {
        return Level_30;
    } else if (level >= 50 && level < 75) {
        return Level_50;
    } else if (level >= 75 && level < 100) {
        return Level_75;
    } else if (level >= 100 && level < 125) {
        return Level_100;
    } else if (level >= 125 && level < 150) {
        return Level_125;
    } // Add more conditions as necessary
    else if (level >= 150 && level < 175) {
        return Level_150;
    } else if (level >= 175 && level < 200) {
        return Level_175;
    } else if (level >= 200 && level < 225) {
        return Level_200;
    } // Continue for other levels
    else if (level >= 225 && level < 250) {
        return Level_225;
    } else if (level >= 250 && level < 275) {
        return Level_250;
    } else if (level >= 275 && level < 300) {
        return Level_275;
    } else if (level >= 300 && level < 325) {
        return Level_300;
    } else if (level >= 325 && level < 350) {
        return Level_325;
    } else if (level >= 350 && level < 375) {
        return Level_350;
    } else if (level >= 375 && level < 400) {
        return Level_375;
    } else if (level >= 400 && level < 425) {
        return Level_400;
    } else if (level >= 425 && level < 450) {
        return Level_425;
    } else if (level >= 450 && level < 475) {
        return Level_450;
    } else if (level >= 475 && level < 500) {
        return Level_475;
    } else if (level >= 500) {
        return Level_500;
    } else {
        // Default case
        return Default;
    }
}

export default getBorderForLevel;
