import {Avatar, ListItem, ListItemAvatar, ListItemText,} from "@mui/material";
import React, {useContext} from "react";
import {ChampionsContext} from "../../context/Champion";
import {ChampionWinRate} from "../../api/Types";
import {DisplayRateNodeWithColor} from "./RateList";

export function FormatWinRateColor(winRate: number): string {
    if (winRate < 46.0) {
        return "#ff0000";
    } else if (winRate < 48.0) {
        return "#ff8000";
    } else if (winRate < 50.0) {
        return "#e6e600";
    } else if (winRate < 55.0) {
        return "#009900";
    } else if (winRate < 60.0) {
        return "#0099ff";
    } else {
        return "#cc33ff";
    }
}

interface WinRateProps {
    data: ChampionWinRate;
}

export const WinRateList: React.FC<WinRateProps> = ({data}) => {
    const {getChampionById} = useContext(ChampionsContext);
    const championObj = getChampionById(data.id);

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar alt={championObj?.name} src={championObj?.iconURL}/>
            </ListItemAvatar>
            <ListItemText
                primary={championObj?.name}
                secondary={
                    <>
                        {DisplayRateNodeWithColor(data.winRate, FormatWinRateColor)}
                        {` win rate over ${data.totalGames} games on the position `}
                        <span style={{fontWeight: "bold"}}>
                                {data.position}
                            </span>
                    </>
                }
            />
        </ListItem>
    );
};