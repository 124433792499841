import {Game, Participants} from "../../../api/Summoner";
import React, {useContext} from "react";
import {Grid, Stack, useTheme} from "@mui/material";
import {ChampionsContext} from "../../../context/Champion";
import {ImageIcon} from "../../utils/Image";

interface FormatPlayerNameProps {
    participant: Participants;
    authorParticipantID: number;
}

const FormatPlayerName: React.FC<FormatPlayerNameProps> = ({participant, authorParticipantID}) => {
    const {getChampionById} = useContext(ChampionsContext);
    const theme = useTheme();
    return (
        <Stack direction="row" spacing={1} sx={{
            mb: 1,
        }}>
            <ImageIcon
                iconPath={getChampionById(participant.championID)?.iconURL}
                size={24}
                alt={getChampionById(participant.championID)?.name}
            />
            {participant.participantID === authorParticipantID ?
                (<span style={{
                    display: 'inline-block',
                    color: theme.palette.primary.main
                }}><strong>{participant.summonerName}</strong></span>) :
                (<span style={{display: 'inline-block'}}>{participant.summonerName}</span>)
            }
        </Stack>
    );
}

interface GameSummaryProps {
    gameData: Game;
    authorParticipantID: number;
}

export const GameSummary: React.FC<GameSummaryProps> = ({gameData, authorParticipantID}) => {
    const blueTeam = gameData.participants.filter(participant => participant.teamID === 100);
    const redTeam = gameData.participants.filter(participant => participant.teamID === 200);

    return (
        <Grid container>
            {Array.from({length: 5}, (_, i) => (
                <React.Fragment key={i}>
                    <Grid item xs={6} key={"BLUE_" + i}>
                        <FormatPlayerName participant={blueTeam[i]} authorParticipantID={authorParticipantID}/>
                    </Grid>
                    <Grid item xs={6} key={"RED_" + i}>
                        <FormatPlayerName participant={redTeam[i]} authorParticipantID={authorParticipantID}/>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    )
}