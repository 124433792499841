import {API_BASE_URL} from "./Constants";
import {RankData} from "../view/utils/Summoner/Rank";

// SummonerPlainResponse is the structure that the API returns when fetching a summoner.
// It is used to convert the response to a more suitable structure for the client.
interface SummonerPlainResponse {
    name: string;
    tagLine: string;
    region: string;
    level: number;
    iconURL: string;
    lastUpdatedAt: number;
    beingFetch: boolean;
    games: Game[];
    ranks: { [key: string]: RankData };
}

export interface Game {
    metadata: Metadata;
    participants: Participants[];
}

export interface Metadata {
    gameID: string;
    gameStartTimestamp: number;
    version: string;
    gameDuration: number;
    queueType: string;
    authorWon: boolean;
    authorParticipantID: number;
}

export interface ItemData {
    finalItems: number[];
    boots: number;
    trinket: number;
    starterItem: number;
}

export interface Participants {
    summonerName: string;
    tagLine: string;
    participantID: number;
    championID: number;
    teamID: number;
    position: string;
    kills: number;
    deaths: number;
    assists: number;
    maximumMultiKill: number;
    visionScore: number;
    runes: Runes;
    minionsKilled: number;
    monstersKilled: number;
    items: ItemData;
    win: boolean;
    totalDamageDealt: number;
    summonerSpell1: number;
    summonerSpell2: number;
}

export interface Runes {
    statPerks: StatPerks;
    styles: null; // TODO: Change to the correct type
}

export interface StatPerks {
    defense: number;
    flex: number;
    offense: number;
}


// SummonerResponse is the structure that the client uses to display the summoner's data.
export interface SummonerResponse {
    name: string;
    tagLine: string;
    region: string;
    level: number;
    iconURL: string;
    lastUpdatedAt: Date | undefined;
    beingFetch: boolean;
    games: Game[];
    ranks: Map<string, RankData>;
}

export async function fetchSummoner(name: string, tagline: string): Promise<SummonerResponse | undefined> {
    try {
        console.log(`fetchSummoner>fetching ${name}#${tagline}`);
        const response = await fetch(`${API_BASE_URL}/summoners/retrieve?name=${name}&tagLine=${tagline}`);
        const jsonResp = await response.json() as SummonerPlainResponse;
        console.log(`fetchSummoner>response:`, jsonResp);

        // Convert ranks from plain object to Map
        const ranksMap = new Map<string, RankData>(Object.entries(jsonResp.ranks));

        // Convert lastUpdated back to Date object
        const lastUpdatedDate = jsonResp.lastUpdatedAt ? new Date(jsonResp.lastUpdatedAt * 1000) : undefined;

        // Return the modified structure that matches SummonerPageData, including the converted Map
        return {
            ...jsonResp, // Spread the rest of jsonResp properties
            ranks: ranksMap,
            lastUpdatedAt: lastUpdatedDate
        };
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return undefined;
    }
}