import {API_BASE_URL} from './Constants';

/**
 * apiVersion is a variable that stores the current major & minor version of the League Of Legends API.
 * It is initially an empty string and is updated by the getApiVersion function.
 *
 * @type {string}
 */
let apiVersion: string = '';

/**
 * getApiVersion is an asynchronous function that returns the current major & minor version of the League Of Legends API.
 * If the apiVersion variable is an empty string, it fetches the API version from the '/version' endpoint and updates the apiVersion variable.
 * It then returns the apiVersion variable.
 *
 * @async
 * @function
 * @returns {Promise<string>} - A promise that resolves to the current major & minor version of the League Of Legends API.
 */
export async function getApiVersion(): Promise<string> {
    if (apiVersion === '') {
        apiVersion = await fetchApiVersion();
    }
    return apiVersion;
}

/**
 * fetchApiVersion is an asynchronous function that fetches the current major & minor version of the League Of Legends API from the '/version' endpoint.
 * It sends a GET request to the '/version' endpoint and returns the version received in the response.
 *
 * @async
 * @function
 * @returns {Promise<string>} - A promise that resolves to the current major & minor version of the League Of Legends API.
 */
async function fetchApiVersion(): Promise<string> {
    try {
        const response: Response = await fetch(API_BASE_URL + '/static/version');
    const data = await response.json();
    return data.version;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return '';
    }
}