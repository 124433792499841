import {Card, CardContent, Chip, Stack, styled} from "@mui/material";
import React from "react";
import {SummonerPageData} from "../Data";
import {Queue} from "../../utils/Summoner/Queue";
import {Game} from "../../../api/Summoner";
import {DisplayGameData} from "./Data";
import {ChampionHeader} from "./ChampionHeader";
import {CardDivider} from "./utils";
import {ItemsDisplay} from "./ItemsDisplay";
import {GameSummary} from "./GameSummary";
import Box from "@mui/material/Box";

interface GamesHistoryProps {
    summoner: SummonerPageData | null;
    queueSelected: Queue;
}

const DefeatCard = styled(Card)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#7e1b1b" : "#db5757",
}));

const VictoryCard = styled(Card)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#377b3b" : "#72c076",
}));

const StyledBox = styled(Box)(({theme}) => ({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
}));

const CardWithGameSummary: React.FC<{ CardType: React.ElementType; game: Game }> = ({CardType, game}) => {
    const authorData = game.participants.find(participant => participant.participantID === game.metadata.authorParticipantID);
    if (!authorData) {
        console.error("Author participant not found in game");
        return null;
    }
    return React.createElement(CardType, {
            variant: "outlined",
            sx: {
                maxWidth: '100rem',
            },
        },
        <React.Fragment>
            <CardContent>
                <Stack direction="row" spacing={2} justifyContent={"space-around"} alignItems={"center"}
                       divider={<CardDivider flexItem orientation="vertical"/>}
                       sx={{width: '100%', alignItems: 'stretch'}}
                >
                    <StyledBox sx={{
                        minWidth: '5rem',
                    }}>
                        <ChampionHeader authorData={authorData} game={game}/>
                    </StyledBox>
                    <StyledBox sx={{
                        minWidth: '13rem',
                    }}>
                        <DisplayGameData game={game} authorData={authorData}/>
                    </StyledBox>
                    <StyledBox>
                        <ItemsDisplay authorData={authorData}/>
                    </StyledBox>
                    <StyledBox>
                        <GameSummary gameData={game} authorParticipantID={authorData.participantID}/>
                    </StyledBox>
                </Stack>
            </CardContent>
        </React.Fragment>
    );
}

export const GamesHistory: React.FC<GamesHistoryProps> = ({summoner, queueSelected}) => {
    const gamesFromSelectedQueue = summoner?.games.filter(game => game.metadata.queueType === queueSelected) || [];
    return (
        <React.Fragment>
            {gamesFromSelectedQueue.length > 0 ? gamesFromSelectedQueue?.map((game, index) => {
                const CardComponent = game.metadata.authorWon ? VictoryCard : DefeatCard;
                return <CardWithGameSummary key={index} CardType={CardComponent} game={game}/>;
            }) : <Chip color="error" label={"No games retrieved. The player's games might be currently fetched."}/>}
        </React.Fragment>
    );
};