import {API_BASE_URL} from "./Constants";

export interface SummonerSpellsData {
    id: number;
    name: string;
    description: string;
    iconURL: string;
}

interface FetchSummonerSepllsResponse {
    summonerSpells: SummonerSpellsData[];
}

export async function fetchSummonerSpells(): Promise<readonly SummonerSpellsData[] | undefined> {
    try {
        const response: Response = await fetch(`${API_BASE_URL}/static/summoner-spells`);
        const resp = await response.json() as FetchSummonerSepllsResponse;
        return resp.summonerSpells;
    } catch (error) {
        console.error('fetchSummonerSpells: There was a problem with the fetch operation:', error);
        return undefined;
    }
}