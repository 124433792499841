import * as React from "react";
import {Chip} from "@mui/material";
import Box from "@mui/material/Box";

interface ShowNotEnoughDataErrorProps {
    label?: string;
}

/**
 * @function ShowNotEnoughDataError
 * @returns {React.FC} - A React functional component that displays an error message when there is not enough data to generate a build.
 */
export const ShowNotEnoughDataError = React.forwardRef<HTMLDivElement, ShowNotEnoughDataErrorProps>((props, ref) => {
    const label = props.label ? props.label : "Not enough data to generate build";
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '2rem',
            }}
        >
            <Chip
                ref={ref}
                color="error"
                label={label}
            />
        </Box>
    )
});
