import {fetchSummonerSpells, SummonerSpellsData} from "../api/SummonerSpells";
import React from "react";

export interface SummonerSpellsContextType {
    summonerSpellsMap: Map<number, SummonerSpellsData>;
    getSummonerSpellById: (id: number) => SummonerSpellsData | undefined;
    getSummonerSpellByName: (name: string) => SummonerSpellsData | undefined;
}

const defaultContext: SummonerSpellsContextType = {
    summonerSpellsMap: new Map<number, SummonerSpellsData>(),
    getSummonerSpellById: () => undefined,
    getSummonerSpellByName: () => undefined,
};

export const SummonerSpellsContext = React.createContext<SummonerSpellsContextType>(defaultContext);

export const SummonerSpellsProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [summonerSpellsMap, setSummonerSpellsMap] = React.useState<Map<number, SummonerSpellsData>>(new Map());

    React.useEffect(() => {
        fetchSummonerSpells().then((summonerSpells: readonly SummonerSpellsData[] | undefined) => {
            if (summonerSpells) {
                const map: Map<number, SummonerSpellsData> = new Map(summonerSpells.map(summonerSpell => [summonerSpell.id, summonerSpell]));
                setSummonerSpellsMap(map);
            }
        });
    }, []);

    const getSummonerSpellById = (id: number) => summonerSpellsMap.get(id);
    const getSummonerSpellByName = (name: string) => {
        const summonerSpellsArray = Array.from(summonerSpellsMap.values());
        return summonerSpellsArray.find(summonerSpell => summonerSpell.name === name);
    };

    return (
        <SummonerSpellsContext.Provider value={{summonerSpellsMap, getSummonerSpellById, getSummonerSpellByName}}>
            {children}
        </SummonerSpellsContext.Provider>
    );
}