import {fetchItems, ItemData} from "../api/Items";
import React from "react";


export interface ItemsContextType {
    itemsMap: Map<number, ItemData>;
    getItemById: (id: number) => ItemData | undefined;
}

const defaultContext: ItemsContextType = {
    itemsMap: new Map<number, ItemData>(),
    getItemById: () => undefined,
};

export const ItemsContext = React.createContext<ItemsContextType>(defaultContext);

export const ItemsProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [itemsMap, setItemsMap] = React.useState<Map<number, ItemData>>(new Map());

    React.useEffect(() => {
        fetchItems().then((items: readonly ItemData[] | undefined) => {
            if (items) {
                const map: Map<number, ItemData> = new Map(items.map(item => [item.id, item]));
                setItemsMap(map);
            }
        })
    }, []);

    const getItemById = (id: number) => itemsMap.get(id)

    return (
        <ItemsContext.Provider value={{itemsMap, getItemById}}>
            {children}
        </ItemsContext.Provider>
    );
};