import * as React from 'react';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import logo2 from "../../assets/images/logo2.png";
import {
    AppBar,
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {ContactSupport, DarkMode, EmojiEvents, Home, InfoOutlined, LightMode} from "@mui/icons-material";
import {SetTheme} from "../../App";
import {darkTheme, lightTheme} from "../utils/Theme";
import MenuIcon from '@mui/icons-material/Menu';

interface RedirectButtonProps {
    toolTip: string;
    button: React.ReactElement;
    url: string;
}

const pages: RedirectButtonProps[] = [
    {toolTip: 'Home', button: <Home/>, url: '/'},
    {toolTip: 'About', button: <InfoOutlined/>, url: '/about'},
    {toolTip: 'Contact', button: <ContactSupport/>, url: '/contact'},
    {toolTip: 'Rates', button: <EmojiEvents/>, url: '/rates'},
];

export const ToolBarMaxWidth = '150vh';

export const ResponsiveAppBar: React.FC<SetTheme> = ({setCurrentTheme}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleNavigation = (url: string) => {
        navigate(url);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const displayButton = (page: RedirectButtonProps) => {
        return (
            <IconButton
                key={page.toolTip}
                size="large"
                edge="start"
                color="inherit"
                onClick={() => handleNavigation(page.url)}
                sx={{mr: 2}}
            >
                {page.button}
                <Typography
                    color="inherit"
                    variant="h6"
                    sx={{ml: 1}}
                >
                    {page.toolTip}
                </Typography>
            </IconButton>
        );
    }

    return (
        <AppBar position="static" sx={{maxWidth: ToolBarMaxWidth, width: '100%', margin: '0 auto'}}>
            <Toolbar disableGutters sx={{
                mr: 3,
                ml: 3,
            }}>
                <Button
                    key={"home-button"}
                    onClick={() => handleNavigation('/')}
                    aria-label="League Item Recommender logo"
                    sx={{mr: 2}}
                >
                    <img src={logo2} alt="League Item Recommender logo" width="70" height="70"/>
                </Button>

                {isMobile ? (
                    <>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{mr: 2, display: {sm: 'none'}}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Drawer
                            anchor="left"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        >
                            <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
                                <List>
                                    {pages.map((page: RedirectButtonProps) => (
                                        <ListItem key={page.toolTip} onClick={() => handleNavigation(page.url)}>
                                            {displayButton(page)}
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Drawer>
                    </>
                ) : (
                    <>
                        {pages.map((page: RedirectButtonProps) => (
                            displayButton(page)
                        ))}
                    </>
                )}
                <Box flexGrow={1}/>

                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    onClick={() => setCurrentTheme(theme.palette.mode === 'dark' ? lightTheme : darkTheme)}
                >
                    {theme.palette.mode === 'dark' ? <LightMode/> : <DarkMode/>}
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default ResponsiveAppBar;