/**
 * ResetTitle is a function that resets the title of the page to the default one or to a custom one if provided.
 * @param {string} title - The new title for the page. If an empty string is provided, the title will be reset to "League Item Recommender".
 */
export const ResetTitle = (title: string = "") => {
    if (title !== "") {
        document.title = title;
    } else {
        document.title = "League Item Recommender";
    }
}