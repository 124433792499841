import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import React from "react";
import {SummonerPageData} from "./Data";
import getBorderForLevel from "../../assets/border";

interface SummonerIconProps {
    summoner: SummonerPageData | null;
}

const displayBorderLevelImage = (level: number): React.ReactNode => {
    let borderImg = getBorderForLevel(level);
    return (
        <Box
            position="absolute"
            sx={{
                width: '190%',
                height: '190%',
                bottom: '-45%',
                background: `url(${borderImg}) no-repeat center center`,
                backgroundSize: 'contain', // This will ensure that the image is contained within the frame, and doesn't stretch
            }}
        >
            <Typography
                variant="caption"
                component="div"
                sx={{
                    position: 'absolute',
                    bottom: 27,
                    width: '100%',
                    // bgcolor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    textAlign: 'center',
                    // borderRadius: '80%',
                    fontSize: '0.75rem',
                }}
            >
                {level}
            </Typography>
        </Box>
    );
}

// SummonerIcon is a component that displays the summoner's icon and level, with a border that changes depending on the level.
export const SummonerIcon: React.FC<SummonerIconProps> = ({summoner}) => {
    return (
        <Box
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                overflow: 'hidden',
                width: '175px',
                height: '175px',
                margin: 'auto', // Center the frame if necessary
            }}
        >
            <Box
                position="relative"
                sx={{
                    width: '100px',
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {displayBorderLevelImage(summoner?.level ?? 0)}
                <img src={summoner?.iconURL} alt="Summoner Icon"
                     style={{width: '100%', height: 'auto', display: 'block', borderRadius: '50%'}}/>
            </Box>
        </Box>
    )
}