import React from 'react';

interface ImageIconProps {
    iconPath?: string;
    size?: number;
    alt?: string;
    style?: React.CSSProperties;
}

export const ImageIcon = React.forwardRef<HTMLImageElement, ImageIconProps>(({
                                                                                 iconPath,
                                                                                 size = 24,
                                                                                 alt,
                                                                                 style = {},
                                                                             }, ref) => (
    <img
        ref={ref}
        src={iconPath}
        alt={alt}
        style={{
            width: size,
            height: size,
            ...style,
        }}
    />
));

export const ImageIconWithBorder = React.forwardRef<HTMLImageElement, ImageIconProps>((props, ref) => {
    return (
        <ImageIcon
            ref={ref}
            style={{
                border: '1px solid black',
            }}
            {...props}
        />
    );
});