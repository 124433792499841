import {ThemeOptions} from '@mui/material/styles';
import {createTheme, SxProps, Theme} from "@mui/material";

const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#e5b15f',
        },
        secondary: {
            main: '#a8150b',
        },
        background: {
            default: '#16171d',
            paper: '#1e1e1e',
        },
    },
    shape: {
        borderRadius: 4,
    },
};

const lightThemeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#e5b15f',
        },
        secondary: {
            main: '#5f93e5',
        },
    },
    shape: {
        borderRadius: 4,
    },
};

export const darkTheme: Theme = createTheme(darkThemeOptions);
export const lightTheme: Theme = createTheme(lightThemeOptions);

export const SxDefaultPageStyle: SxProps<Theme> = {
    overflowX: 'hidden',
    marginTop: {xs: 3, sm: 6.25, xl: 7},
    marginBottom: {xs: 3, sm: 6.25, xl: 7},
    marginLeft: {xs: 1, sm: 15, xl: 35},
    marginRight: {xs: 1, sm: 15, xl: 35},
};