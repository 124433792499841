import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {Champion} from "../api/Types";
import {fetchChampions} from "../api/Champions";


export interface ChampionsContextType {
    championsMap: Map<number, Champion>;
    getChampionByName: (name: string) => Champion | undefined;
    getChampionById: (id: number) => Champion | undefined;
}

const defaultContext: ChampionsContextType = {
    championsMap: new Map<number, Champion>(),
    getChampionByName: () => undefined,
    getChampionById: () => undefined,
};

export const ChampionsContext = createContext<ChampionsContextType>(defaultContext);

export const ChampionsProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [championsMap, setChampionsMap] = useState<Map<number, Champion>>(new Map());

    useEffect(() => {
        fetchChampions().then((champions: Champion[] | undefined) => {
            if (champions) {
                const map: Map<number, Champion> = new Map(champions.map(champion => [champion.id, champion]));
                setChampionsMap(map);
            }
        });
    }, []);

    const getChampionByName = (name: string): Champion | undefined => {
        const championsArray = Array.from(championsMap.values());
        return championsArray.find(champion => champion.name === name);
    };

    const getChampionById = (id: number) => championsMap.get(id)

    return (
        <ChampionsContext.Provider value={{championsMap, getChampionByName, getChampionById}}>
            {children}
        </ChampionsContext.Provider>
    );
};