import {Grid, Stack, Typography} from "@mui/material";
import {ImageIconWithBorder} from "../../utils/Image";
import React, {useContext} from "react";
import {ItemsContext} from "../../../context/Items";
import {Participants} from "../../../api/Summoner";

export interface ItemsDisplayProps {
    authorData: Participants;
}

export const ItemsDisplay: React.FC<ItemsDisplayProps> = ({authorData}) => {
    const {getItemById} = useContext(ItemsContext);
    return (
        <Stack>
            <Typography variant="body1" textAlign="center">
                <strong>Items</strong>
            </Typography>
            <Grid direction="row" spacing={1} container>
                <Grid item xs={6}>
                    <Typography variant="body1">Starter</Typography>
                </Grid>
                <Grid item xs={6}>
                    {getItemById(authorData.items.starterItem) &&
                        <ImageIconWithBorder iconPath={getItemById(authorData.items.starterItem)?.iconURL}
                                             alt={getItemById(authorData.items.starterItem)?.name}
                                             size={24}/>}
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">Boots</Typography>
                </Grid>
                <Grid item xs={6}>
                    {getItemById(authorData.items.boots) &&
                        <ImageIconWithBorder iconPath={getItemById(authorData.items.boots)?.iconURL}
                                             alt={getItemById(authorData.items.boots)?.name} size={24}/>}
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">Trinket</Typography>
                </Grid>
                <Grid item xs={6}>
                    {getItemById(authorData.items.trinket) &&
                        <ImageIconWithBorder iconPath={getItemById(authorData.items.trinket)?.iconURL}
                                             alt={getItemById(authorData.items.trinket)?.name} size={24}/>}
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">Finished</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="row" spacing={0.5}>
                        {authorData.items.finalItems.map((item, index) => {
                            const i = getItemById(item);
                            return <ImageIconWithBorder key={index} iconPath={i?.iconURL} alt={i?.name}
                                                        size={24}/>;
                        })}
                    </Stack>
                </Grid>
            </Grid>
        </Stack>
    )
}