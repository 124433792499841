import {ChampionBanRate} from "../../api/Champions";
import React, {useContext} from "react";
import {Avatar, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {ChampionsContext} from "../../context/Champion";
import {DisplayRateNodeWithColor} from "./RateList";

interface BanRateProps {
    data: ChampionBanRate;
}


function FormatWinRateColor(winRate: number): string {
    if (winRate < 46.0) {
        return "#ff0000";
    } else if (winRate < 48.0) {
        return "#ff8000";
    } else if (winRate < 50.0) {
        return "#e6e600";
    } else if (winRate < 55.0) {
        return "#009900";
    } else if (winRate < 60.0) {
        return "#0099ff";
    } else {
        return "#cc33ff";
    }
}

export const BanRateList: React.FC<BanRateProps> = ({data}) => {
    const {getChampionById} = useContext(ChampionsContext);
    const championObj = getChampionById(data.championID);

    return (
        <ListItem key={data.championID}>
            <ListItemAvatar>
                <Avatar alt={championObj?.name} src={championObj?.iconURL}/>
            </ListItemAvatar>
            <ListItemText
                primary={championObj?.name}
                secondary={
                    <>
                    {DisplayRateNodeWithColor(data.banRate, FormatWinRateColor)}
                    {` in total of ${data.totalBans} games`}
                    </>
                }
            />
        </ListItem>
    );
}