import React from "react";
import {List, ListItem} from "@mui/material";

interface BulletPointProps {
    children: React.ReactNode;
}

export const BulletPoint: React.FC<BulletPointProps> = ({children}) => {
    return (
        <List
            sx={{
                listStyleType: 'disc',
                pl: 3,
                '& .MuiListItem-root': {
                    display: 'list-item',
                },
            }}>
            <ListItem sx={{display: 'list-item'}}>
                {children}
            </ListItem>
        </List>
    );
};