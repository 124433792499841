import React from "react";
import {useInView} from "react-intersection-observer";
import {Typography} from "@mui/material";

interface ScrollFadeInTextProps {
    children: React.ReactNode;
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export const ScrollFadeInText: React.FC<ScrollFadeInTextProps> = ({children, variant}) => {
    const {ref, inView} = useInView({
        triggerOnce: true,
        threshold: 0.50,
    });

    return (
        <Typography
            variant={variant}
            component="div"
            ref={ref}
            sx={{
                marginBottom: "1rem",
                opacity: inView ? 1 : 0,
                transform: inView ? 'translateY(0)' : 'translateY(20px)',
                transition: 'opacity 0.75s ease-out, transform 0.75s ease-out'
            }}
        >
            {children}
        </Typography>
    );
};