import {
    Alert,
    AlertTitle,
    Autocomplete,
    IconButton,
    Paper,
    Slide,
    Snackbar,
    Stack,
    styled,
    TextField,
    Typography
} from "@mui/material";
import {Champion} from "../../api/Types";
import Box from "@mui/material/Box";
import {PositionSelector} from "./PositionSelector";
import React, {SyntheticEvent} from "react";
import {fetchChampions} from "../../api/Champions";
import {useNavigate} from "react-router-dom";
import {ResetTitle} from "../utils/Title";
import {fetchSummoner} from "../../api/Summoner";
import {SummonerPageData} from "../Summoner/Data";

interface SearchBarProps {
    onChampionSelected: (champion: Champion | null) => void;
    onPositionSelected: (position: string) => void;
}

interface SearchBarChampionOptions extends Champion {
    url: string;
}

interface SearchBarSummonerOptions extends SummonerPageData {
    url: string;
}

type SearchBarOptions = SearchBarChampionOptions | SearchBarSummonerOptions;

const CodeTypography = styled(Typography)(({theme}) => ({
    component: 'div',
    fontFamily: 'monospace',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    fontSize: '0.875rem',
    lineHeight: '1.5'
}));

const SearchBarLabel = () => {
    return (
        <Stack direction="row" spacing={1} alignItems="center" alignContent="center" justifyContent="center">
            <Typography variant="body2" component="div" style={{lineHeight: '1.5', fontSize: '1.15rem'}}>
                Summoner name
            </Typography>
            <CodeTypography>
                #EUW
            </CodeTypography>
        </Stack>
    );
}


function useDebounce(value: string, delay: number, initialValue: string = "") {
    const [state, setState] = React.useState(initialValue);

    React.useEffect(() => {
        const timer = setTimeout(() => setState(value), delay);
        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);
    return state;
}

export const SeachBar: React.FC<SearchBarProps> = ({onPositionSelected}) => {
    const [options, setOptions] = React.useState<SearchBarOptions[]>([])
    const [filteredOptions, setFilteredOptions] = React.useState<SearchBarOptions[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [inputValue, setInputValue] = React.useState('');
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [isEnterPressed, setIsEnterPressed] = React.useState(false);
    const navigate = useNavigate();

    const handleNavigation = (url: string, option: SearchBarOptions) => {
        navigate(url, {state: {item: option}});
    };

    const debouncedValue = useDebounce(inputValue, 750);

    React.useEffect(() => {
        if (debouncedValue.length > 0 && debouncedValue.length < 16 && filteredOptions.length === 0) {
            let [name, tagLine] = debouncedValue.split('#');
            if (tagLine === undefined) {
                tagLine = "EUW"; // TODO: Add region selection
            }
            fetchSummoner(name, tagLine).then((summoner) => {
                if (summoner !== undefined && summoner.name !== undefined && summoner.tagLine !== undefined) {
                    const summonerPageData: SearchBarSummonerOptions = {
                        ...summoner,
                        url: `/summoner?name=${debouncedValue}&tagline=${tagLine}&region=EUW`,
                    }
                    setOptions([...options, summonerPageData]);
                    if (isEnterPressed) {
                        handleNavigation(summonerPageData.url, summonerPageData);
                    }
                } else {
                    console.error("Error fetching summoner", summoner);
                    setSnackbarOpen(true);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue, filteredOptions.length]);

    React.useEffect(() => {
        ResetTitle();
        setLoading(true);
        fetchChampions().then((champions: Champion[] | undefined) => {
            if (champions) {
                setOptions(champions.map(champion => ({...champion, url: `/champion/${champion.name.toLowerCase()}`})));
            } else {
                setOptions([]);
            }
            setLoading(false);
        })
    }, []);

    React.useEffect(() => {
        const results = options.filter(option =>
            option.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredOptions(results);
    }, [options, inputValue]);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, value: string | SearchBarOptions | null) => {
        console.log("handleChange", value, typeof value === "object")
        if (value !== null && typeof value === "object") {
            const optionSelected = options.find((option: SearchBarOptions) => option.name === value.name);
            if (optionSelected !== undefined) {
                handleNavigation(optionSelected.url, optionSelected);
            }
        } else {
            setIsEnterPressed(true);
        }
    };

    const handleInput = (event: SyntheticEvent<Element, Event>, value: string) => {
        setInputValue(value);
        setIsEnterPressed(false);
    }

    return (
        <Paper variant={"outlined"} sx={{
            display: 'flex',
            flexDirection: {xs: 'column', sm: 'row'}, // Stack on small screens, row on larger
            alignItems: 'center',
            marginTop: {xs: '0.25rem', sm: '1rem'},
            marginBottom: {xs: '0.25rem', sm: '2rem'},
            padding: {xs: '1.5rem', sm: '4rem'},
            borderRadius: 5
        }}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={5000}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                key="snackbar-summoner-not-found"
            >
                <Slide direction="down" in={snackbarOpen}>
                    <Alert
                        sx={{bgcolor: 'background.paper'}}
                        variant="outlined"
                        onClose={() => {
                            setSnackbarOpen(false);
                        }}
                        severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {`Summoner ${debouncedValue} not found`}
                    </Alert>
                </Slide>
            </Snackbar>
            <Autocomplete
                loading={loading}
                freeSolo
                autoComplete
                options={options}
                getOptionLabel={(option: SearchBarOptions | string) => {
                    if (typeof option === "string") {
                        return option;
                    }
                    return option.name;
                }}
                sx={{
                    borderRadius: 15,
                    width: {xs: '100%', sm: '80%', md: '60%', lg: '50%'},
                    minWidth: {xs: '25%', sm: '360px', md: '480px', lg: '720px'},
                }}
                groupBy={(option: SearchBarOptions) => ('tagLine' in option) ? 'Players' : 'Champions'}
                autoHighlight
                onInputChange={handleInput}
                onChange={handleChange}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                renderInput={(params) => (
                    <TextField {...params} label={<SearchBarLabel/>}/>
                )}
                renderOption={(props, option: SearchBarOptions) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                            loading="lazy"
                            width="40"
                            srcSet={`${option.iconURL}`}
                            src={`${option.iconURL}`}
                            alt={option.name}
                        />
                        {option.name}
                    </Box>
                )}
            />
            <IconButton color="primary" size="large" sx={{mt: {xs: 1, sm: 0}}}>
                <PositionSelector onPositionSelected={(position: string) => {
                    onPositionSelected(position);
                }}/>
            </IconButton>
        </Paper>
    );
}