export enum Rank {
    Unranked = "UNRANKED",
    Iron = "IRON",
    Bronze = "BRONZE",
    Silver = "SILVER",
    Gold = "GOLD",
    Platinum = "PLATINUM",
    Emerald = "EMERALD",
    Diamond = "DIAMOND",
    Master = "MASTER",
    Grandmaster = "GRANDMASTER",
    Challenger = "CHALLENGER"
}

export enum Division {
    I = "I",
    II = "II",
    III = "III",
    IV = "IV"
}

export interface RankData {
    queueType: string;
    rank: Rank;
    tier: Division;
    leaguePoints: number;
}