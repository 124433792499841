import * as React from "react";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";

/**
 * @function ShowCircularProgress
 * @returns {React.FC} - A React functional component that displays a circular progress indicator.
 */
export const ShowCircularProgress = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
    return (
        <Box
            ref={ref}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '2rem',
            }}
        >
            <CircularProgress/>
        </Box>
    );
});