import React from "react";
import {ChampionWinRate} from "../../api/Types";
import {
    ChampionBanRate,
    getBanRateLadder,
    getBestChampionsWinRate,
    getWorstChampionsWinRate
} from "../../api/Champions";
import {DefaultPosition, PositionSelector} from "../LandingPage/PositionSelector";
import Box from "@mui/material/Box";
import {Grid, Paper, Stack, Tab} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import {TabList} from "@mui/lab";
import {ChampionsProvider} from "../../context/Champion";
import TabPanel from "@mui/lab/TabPanel";
import {ChampionData, RateList} from "./RateList";
import Typography from "@mui/material/Typography";
import {WinRateList} from "./WinRate";
import {BanRateList} from "./BanRate";
import {SxDefaultPageStyle} from "../utils/Theme";

export const RatesPage = () => {
    const [bestChampionWinRate, setBestChampionWinRate] = React.useState<readonly ChampionWinRate[]>([]);
    const [worstChampionsWinRate, setWorstChampionsWinRate] = React.useState<readonly ChampionWinRate[]>([]);
    const [championBanRate, setChampionBanRate] = React.useState<readonly ChampionBanRate[]>([]);
    const [bestWinRatePosition, setBestWinRatePosition] = React.useState<string>(DefaultPosition);
    const [worstWinRatePosition, setWorstWinRatePosition] = React.useState<string>(DefaultPosition);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [bestChampionWinRateErrState, setBestChampionWinRateErrState] = React.useState<boolean>(false);
    const [worstChampionsWinRateErrState, setWorstChampionsWinRateErrState] = React.useState<boolean>(false);
    const [championBanRateErrState, setChampionBanRateErrState] = React.useState<boolean>(false);
    const [tabSelected, setTabSelected] = React.useState('0');
    const tabsRef = React.useRef<HTMLDivElement[]>([]);

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setTabSelected(newValue);
        scrollToList();
    };

    const scrollToList = React.useCallback(() => {
        tabsRef.current[Number(tabSelected)]?.scrollIntoView({behavior: "smooth"});
    }, [tabSelected]);

    React.useEffect(() => {
        getBanRateLadder().then((championsBanRate: ChampionBanRate[] | undefined) => {
            setChampionBanRate(championsBanRate ?? []);
            if (championsBanRate === undefined || championsBanRate.length === 0) {
                setChampionBanRateErrState(true);
            }
            scrollToList();
        });
    }, [scrollToList]);

    React.useEffect(() => {
        setLoading(true);
        setBestChampionWinRateErrState(false);
        getBestChampionsWinRate(bestWinRatePosition).then((bestWinRates: ChampionWinRate[] | undefined) => {
            if (bestWinRates === undefined || bestWinRates.length === 0) {
                setBestChampionWinRateErrState(true);
            }
            setBestChampionWinRate(bestWinRates ?? []);
            scrollToList();
            setLoading(false);
        });
    }, [bestWinRatePosition, scrollToList]);

    React.useEffect(() => {
        setLoading(true);
        setWorstChampionsWinRateErrState(false);
        getWorstChampionsWinRate(worstWinRatePosition).then((worstWinRates: ChampionWinRate[] | undefined) => {
            if (worstWinRates === undefined || worstWinRates.length === 0) {
                setWorstChampionsWinRateErrState(true);
            }
            setWorstChampionsWinRate(worstWinRates ?? []);
            scrollToList();
            setLoading(false);
        });
    }, [worstWinRatePosition, scrollToList]);

    return (
        <Box sx={{...SxDefaultPageStyle}}>
            <Grid container justifyContent="center">
                <Grid item xs={10}>
                    <Paper sx={{typography: 'body1', justifyContent: 'center'}}>
                        <TabContext value={tabSelected}>
                            <Box sx={{borderBottom: 1}}>
                                <TabList onChange={handleTabChange} aria-label="Tabs-rate" variant={"scrollable"}
                                         scrollButtons>
                                    <Tab label="Best win rate" value="0"/>
                                    <Tab label="Worst win rate" value="1"/>
                                    <Tab label="Ban rate" value="2"/>
                                </TabList>
                            </Box>
                            <ChampionsProvider>
                                <TabPanel value="0">
                                    <RateList key="bestWinRate"
                                              ref={(el: HTMLDivElement) => {
                                                  if (el && tabsRef.current[0] == null)
                                                      tabsRef.current[0] = el
                                              }}
                                              loading={loading}
                                              error={bestChampionWinRateErrState}
                                              title={
                                                  <Typography variant="h4">
                                                      Top {bestChampionWinRate.length} champions win rate
                                                  </Typography>
                                              }
                                              rates={bestChampionWinRate}
                                              listItem={
                                                  (data: ChampionData) => {
                                                      return (
                                                          <WinRateList data={data as ChampionWinRate}/>
                                                      )
                                                  }
                                              }
                                              subTitle={
                                                  <Stack direction="row" spacing={2} alignItems="center">
                                                      <Box sx={{flexGrow: 1}}>
                                                          <PositionSelector onPositionSelected={(position: string) => {
                                                              setBestWinRatePosition(position);
                                                          }}/>
                                                      </Box>
                                                  </Stack>
                                              }
                                    />
                                </TabPanel>
                                <TabPanel value="1">
                                    <RateList key="worstWinRate"
                                              loading={loading}
                                              ref={(el: HTMLDivElement) => {
                                                  if (el && tabsRef.current[1] == null)
                                                      tabsRef.current[1] = el
                                              }}
                                              error={worstChampionsWinRateErrState}
                                              title={
                                                  <Typography variant="h4">
                                                      Worst {worstChampionsWinRate.length} champions win rate
                                                  </Typography>
                                              }
                                              rates={worstChampionsWinRate}
                                              listItem={
                                                  (data: ChampionData) => {
                                                      return (
                                                          <WinRateList data={data as ChampionWinRate}/>
                                                      )
                                                  }
                                              }
                                              subTitle={
                                                  <Stack direction="row" spacing={2} alignItems="center">
                                                      <Box sx={{flexGrow: 1}}>
                                                          <PositionSelector onPositionSelected={(position: string) => {
                                                              setWorstWinRatePosition(position);
                                                          }}/>
                                                      </Box>
                                                  </Stack>
                                              }
                                    />
                                </TabPanel>
                                <TabPanel value="2">
                                    <RateList key="banRate"
                                              loading={loading}
                                              ref={(el: HTMLDivElement) => {
                                                  if (el && tabsRef.current[2] == null)
                                                      tabsRef.current[2] = el
                                              }}
                                              error={championBanRateErrState}
                                              title={
                                                  <Typography variant="h4">
                                                      Top {championBanRate.length} champions ban rate
                                                  </Typography>
                                              }
                                              rates={championBanRate}
                                              listItem={
                                                  (data: ChampionData) => {
                                                      return (
                                                          <BanRateList data={data as ChampionBanRate}/>
                                                      )
                                                  }
                                              }
                                    />
                                </TabPanel>
                            </ChampionsProvider>
                        </TabContext>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}