import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {SxDefaultPageStyle} from "../utils/Theme";
import {ScrollFadeInText} from "../utils/Fade";
import React from "react";
import {MainColorDivider} from "../utils/Divider";
import {
    Avatar,
    Divider,
    FormControl,
    MenuItem,
    MenuList,
    Paper,
    Stack,
    styled,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import discordLogo from "../../assets/logo/discord.svg";
import twitterLogo from "../../assets/logo/X_logo.svg";
import {ContactMail} from "@mui/icons-material";

export const DiscordLink = "https://discord.gg/qcc6XMyFux";
export const TwitterLink = "https://twitter.com/league_item";

const DiscordModule = () => {
    return (
        <Box>
            <Typography variant={"h4"}>Discord</Typography>
            <br/>
            <Typography>
                Join our Discord server to chat with other users, ask questions, and get help from the community.
            </Typography>
            <br/>
            <Button target={"_blank"} variant="contained" color="primary" href={DiscordLink}>Join our Discord
                server</Button>
        </Box>
    )
}

const TwitterModule = () => {
    return (
        <Box>
            <Typography variant={"h4"}>X</Typography>
            <br/>
            <Typography>
                Follow us on X to stay up to date with the latest news and updates:
            </Typography>
            <br/>
            <Button target={"_blank"} variant="contained" color="primary" href={TwitterLink}>Follow us on X</Button>
        </Box>
    )
}

const RegexEmail = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$");

interface ContactFormProps {
    isMobile: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({isMobile}) => {
    const [email, setEmail] = React.useState<string | undefined>(undefined);
    const [message, setMessage] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState<boolean>(false);
    const [messageError, setMessageError] = React.useState<boolean>(false);

    return (
        <FormControl>
            <TextField
                fullWidth
                required
                id="email-required"
                label="Email address"
                type="email"
                value={email || ''}
                error={emailError}
                helperText={email !== undefined && !email.includes('@') ? "Please enter a valid email address" : undefined}
                onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(!RegexEmail.test(e.target.value));
                }}
            />
            <br/>
            <TextField
                id="text-required"
                label="What's on your mind?"
                multiline
                required
                minRows={isMobile ? 2 : 12}
                maxRows={isMobile ? 4 : 24}
                fullWidth
                value={message}
                error={messageError}
                helperText={message.length < 10 ? "Message must be at least 10 characters long" : message.length > 1000 ? "Message must be at most 1000 characters long" : undefined}
                onChange={(e) => {
                    setMessage(e.target.value);
                    setMessageError(e.target.value.length < 10 || e.target.value.length > 1000);
                }}
            />
            <br/>
            <Button disabled={emailError || (messageError || message.length === 0) || true}
                    variant="contained"
                    color="primary">Submit</Button>
        </FormControl>
    )
}

enum Scene {
    ContactForm,
    Discord,
    Twitter,
}

const StyledCenteredMenuItem = styled(MenuItem)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const ContactPage = () => {
    const [scene, setScene] = React.useState<Scene>(Scene.ContactForm);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        document.title = "Contact us - League Item Recommender";
    }, []);
    return (
        <Box
            sx={{
                ...SxDefaultPageStyle,
                p: isMobile ? 2 : 3,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <ScrollFadeInText>
                <Typography variant={"h2"} component={"h1"}>Contact us!</Typography>
                <br/>
                <MainColorDivider variant="fullWidth" orientation="horizontal"/>
            </ScrollFadeInText>
            <ScrollFadeInText>
                <p>
                    Welcome to our Contact Page!
                    <br/>
                    At the heart of the League Item Recommender project
                    is a commitment to open, two-way communication with our users and community. Whether you have
                    questions, feedback, or ideas, we're all ears. Your insights and experiences are invaluable to us as
                    they help shape the future of our tool and ensure it meets your needs and expectations.
                </p>
                <p>
                    Don't hesitate to reach out; whether it's a suggestion for improvement, a technical issue, or just a
                    hello, we're here to listen and respond. Let's make the League Item Recommender the best it can be,
                    together.
                </p>
                <br/>
                <Paper sx={{
                    p: '3rem',
                    mr: {sm: 0, md: '4rem', l: '8rem', xl: '12rem'},
                    ml: {sm: 0, md: '4rem', l: '8rem', xl: '12rem'},
                    minHeight: '60vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch', // This ensures children stretch to fill the container width
                }} variant={"outlined"}>
                    <Typography variant={"h4"} component={"h2"} alignItems="center" sx={{
                        mb: "1rem",
                    }}>
                        How to contact us?
                    </Typography>
                    <MainColorDivider variant="fullWidth" orientation="horizontal"/>
                    <Stack
                        direction={isMobile ? 'column' : 'row'}
                        alignItems={isMobile ? 'center' : 'stretch'}
                        justifyContent="space-around"
                        spacing={isMobile ? 2 : 4}
                        divider={<Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem/>}
                        sx={{
                            maxWidth: '100%',
                            margin: 'auto',
                            flexGrow: 1,
                            width: '100%'
                        }}
                    >
                        <MenuList
                            sx={{
                                width: {sm: '10%', lg: '5%'},
                                maxWidth: isMobile ? 'initial' : 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                flexGrow: 1,
                            }}
                        >
                            <StyledCenteredMenuItem onClick={() => setScene(Scene.ContactForm)}>
                                <ContactMail fontSize='large'/>
                            </StyledCenteredMenuItem>
                            <StyledCenteredMenuItem onClick={() => setScene(Scene.Discord)}>
                                <Avatar src={discordLogo}/>
                            </StyledCenteredMenuItem>
                            <StyledCenteredMenuItem onClick={() => setScene(Scene.Twitter)}>
                                <Avatar src={twitterLogo}/>
                            </StyledCenteredMenuItem>
                        </MenuList>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                width: '100%',
                                flexGrow: 1,
                                margin: isMobile ? '0 auto' : 'initial',
                                padding: isMobile ? theme.spacing(2) : theme.spacing(4),
                                ml: isMobile ? '0' : theme.spacing(4),
                            }}
                        >
                            {scene === Scene.ContactForm && <ContactForm isMobile={isMobile}/>}
                            {scene === Scene.Discord && <DiscordModule/>}
                            {scene === Scene.Twitter && <TwitterModule/>}
                        </Box>
                    </Stack>
                </Paper>
            </ScrollFadeInText>
        </Box>
    )
}