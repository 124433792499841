import {Rank, RankData} from "../utils/Summoner/Rank";
import Box from "@mui/material/Box";
import {
    Card,
    CardContent,
    CardHeader,
    Chip,
    LinearProgress,
    linearProgressClasses,
    styled,
    Typography
} from "@mui/material";
import React from "react";
import {SummonerPageData} from "./Data";
import {getImageFromRank} from "../../assets/rank";
import {AvailableQueues, QueuesNames} from "../utils/Summoner/Queue";

const RankHasOnlyOneTier = (rank: string | undefined) => {
    if (rank === undefined) {
        return true;
    }
    switch (rank.toUpperCase()) {
        case Rank.Unranked:
        case Rank.Master:
        case Rank.Grandmaster:
        case Rank.Challenger:
            return true;
        default:
            return false;
    }
}

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
    },
}));

const BorderLinearProgressWithLabel = (props: { label: number, value: number }) => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'no-wrap', marginTop: '0.5rem'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <BorderLinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)} LP`}</Typography>
            </Box>
        </Box>
    );
}

const DisplayQueueRank = (defaultQueueName: string | undefined, rank: RankData | undefined) => {
    console.log(rank?.queueType, rank?.tier, rank?.rank, rank?.leaguePoints)
    return (
        <Card variant="outlined" sx={{
            mb: '0.5rem',
        }}>
            <CardHeader title={defaultQueueName}/>
            <CardContent>
                <Box display="flex" justifyContent="center" alignContent={"center"} flexDirection={"column"}
                     alignItems={"center"} sx={{}}>
                    <img loading={"lazy"} style={{
                        width: "150px",
                        height: "150px",
                    }} src={getImageFromRank(rank?.tier ?? "UNRANKED")} alt={rank?.tier ?? "UNRANKED"}/>
                    {rank?.rank && RankHasOnlyOneTier(rank?.tier) ? `${rank.tier}` : rank?.rank ? `${rank?.tier} ${rank?.rank}` : "UNRANKED"}
                </Box>
                <BorderLinearProgressWithLabel
                    label={rank !== undefined ? rank.leaguePoints > 100 ? 99 : rank.leaguePoints : 0}
                    value={rank !== undefined ? rank.leaguePoints : 0}
                />
            </CardContent>
        </Card>
    )
}

export interface QueueRanksProps {
    summoner: SummonerPageData | null;
}

export const QueueRanks: React.FC<QueueRanksProps> = ({summoner}) => {
    return (
        <Box sx={{
            maxWidth: '30rem',
        }}>
            {summoner ? AvailableQueues.map((queue) => (
                <React.Fragment key={queue}>
                    {DisplayQueueRank(QueuesNames.get(queue), summoner.ranks?.get(queue) ?? undefined)}
                </React.Fragment>
            )) : <Chip color="error" label={"Unable to fetch data"}/>}
        </Box>
    );
};