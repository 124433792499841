import Box from "@mui/material/Box";
import React from "react";
import {Link, Typography} from "@mui/material";
import {ScrollFadeInText} from "../utils/Fade";
import {BulletPoint} from "../utils/BulletPoint";
import {SxDefaultPageStyle} from "../utils/Theme";
import {MainColorDivider} from "../utils/Divider";
import {DiscordLink} from "../Contact";


export const AboutPage = () => {
    const discordColor = "#7289da";
    return (
        <Box sx={SxDefaultPageStyle}>
            <ScrollFadeInText>
                <Typography variant={"h3"} component={"h1"}>About League Item Recommender</Typography>
                <br/>
                <MainColorDivider variant="fullWidth" orientation="horizontal"/>
            </ScrollFadeInText>
            <div style={{
                marginBottom: "5rem",
            }}>
                <ScrollFadeInText variant={"h6"}>
                    In the ever-evolving world of League of Legends, where strategy meets skill, the right item choices
                    can turn the tide of battle. That's where we come in. The League Item Recommender is your premier
                    tool
                    for harnessing the power of data-driven decisions to elevate your gameplay. Built by enthusiasts for
                    the
                    community, our mission is to demystify the complexities of item selection, making it accessible and
                    actionable for every player, regardless of their experience level.
                </ScrollFadeInText>
                <ScrollFadeInText variant={"h6"}>
                    Our tool taps into the vast ocean of game data, analyzing patterns, trends, and performance metrics
                    to offer personalized item recommendations that align with your unique play style and objectives.
                    Whether you're strategizing for the next big tournament or looking to climb the ranks in solo queue,
                    the
                    League Item Recommender is designed to give you a competitive edge.
                </ScrollFadeInText>
            </div>

            <ScrollFadeInText>
                <Typography variant={"h3"} component={"h2"}>Our Story</Typography>
                <br/>
                <MainColorDivider variant="fullWidth" orientation="horizontal"/>
            </ScrollFadeInText>
            <Typography variant={"h6"} mb={"5rem"}>
                <ScrollFadeInText variant={"h6"}>
                    In the fast-paced world of online gaming, where strategy meets agility, the inception of the League
                    Item Recommender was sparked by a simple yet profound question: "How can we empower players to make
                    data-driven decisions that enhance their gameplay?" From this question, a project was born, driven
                    by a team of passionate developers, data scientists, and avid gamers who believed in the power of
                    technology to transform the gaming experience.
                </ScrollFadeInText>
                <ScrollFadeInText variant={"h6"}>
                    Our journey began in the vibrant community of League of Legends players, where we recognized a
                    common challenge—navigating the vast array of item choices to optimize in-game performance. With
                    countless
                    combinations and strategies, players often relied on trial and error or followed the crowd, missing
                    out on personalized strategies that could elevate their play. It was here that we saw an opportunity
                    to
                    bridge the gap between data analytics and gaming strategy, creating a tool that could analyze vast
                    amounts of gameplay data to recommend item builds tailored to individual playing styles and the
                    ever-evolving game meta.
                </ScrollFadeInText>
                <ScrollFadeInText variant={"h6"}>As we launched our initial version for public testing, we were met with
                    invaluable feedback from our
                    community. Each piece of advice, each suggestion, has been a stepping stone towards improvement,
                    guiding
                    the evolution of our tool. Today, the League Item Recommender stands as a testament to the
                    collaborative
                    spirit of the gaming community and the innovative potential of technology in enhancing the gaming
                    experience.
                </ScrollFadeInText>
                <ScrollFadeInText variant={"h6"}>
                    Our vision for the future is ambitious. We aim not only to refine and expand our tool's capabilities
                    but
                    also to foster a community where gamers and developers can come together to share insights,
                    strategies,
                    and innovations. The road ahead is filled with possibilities, and we are committed to exploring
                    every
                    avenue to make the League Item Recommender an indispensable ally for gamers worldwide.
                </ScrollFadeInText>
            </Typography>

            <ScrollFadeInText>
                <Typography variant={"h3"} component={"h2"}>Contact us</Typography>
                <br/>
                <MainColorDivider variant="fullWidth" orientation="horizontal"/>
            </ScrollFadeInText>
            <Typography variant={"h6"} mb={"5rem"}>
                <ScrollFadeInText variant={"h6"}>
                    We're always eager to hear from you! Whether you have questions, feedback, or ideas, your input is
                    invaluable to us. Here's how you can reach out:
                </ScrollFadeInText>
                <ScrollFadeInText variant={"h6"}>
                    <BulletPoint>
                        <Typography variant={"inherit"} sx={{textDecoration: 'underline'}} display="inline">Email
                            us:</Typography> Drop us a line at <Link
                        href={`mailto:contact@league-item-recommender.com`}>contact@league-item-recommender.com</Link>.
                        Whether it's feedback on the tool, inquiries about collaboration, or just to say hello, we're
                        here to listen.
                    </BulletPoint>
                    <BulletPoint>
                        <Typography variant={"inherit"} sx={{textDecoration: 'underline'}} display="inline">Follow and
                            Message us on social media:</Typography> Stay connected and engage with us through our
                        social
                        media platforms. We're active on Twitter, Facebook, and Instagram. Follow us for the latest
                        updates and feel free to send us a message anytime.
                    </BulletPoint>
                    <BulletPoint>
                        <Typography variant={"inherit"} sx={{textDecoration: 'underline'}} display="inline">Join
                            Our <Link target={"_blank"} color={discordColor} underline={"hover"}
                                      href={DiscordLink}>Discord</Link> Community:</Typography> For
                        real-time discussions, support, and to become part of our
                        growing community, join our Discord server. It's a great place to meet other users, share
                        experiences, and get direct support from the team.
                    </BulletPoint>
                </ScrollFadeInText>
                <ScrollFadeInText variant={"h6"}>
                    We look forward to hearing from you and welcoming you into our community. Your thoughts and
                    contributions help us make the League Item Recommender even better.
                </ScrollFadeInText>
            </Typography>
        </Box>
    )
}