import {API_BASE_URL} from "./Constants";

/**
 * ItemData is an interface that represents the structure of an item in the application.
 * It includes the item's id, name, and the URL of the item's icon.
 *
 * @interface
 * @property {number} id - The unique identifier of the item.
 * @property {string} name - The name of the item.
 * @property {string} iconURL - The URL of the item's icon.
 */
export interface ItemData {
    id: number;
    name: string;
    iconURL: string;
}

/**
 * FetchItemsResponse is an interface that represents the structure of the response from the fetchItems API call.
 * It includes an array of items, each item following the structure defined by the ItemData interface.
 *
 * @interface
 * @property {ItemData[]} items - An array of items.
 */
interface FetchItemsResponse {
    items: ItemData[];
}

/**
 * fetchItems is an asynchronous function that fetches the list of items from the API.
 * It sends a GET request to the '/items/list' endpoint and returns the items received in the response.
 * If there is an error during the fetch operation, it logs the error and returns undefined.
 *
 * @async
 * @function
 * @returns {Promise<readonly ItemData[]|undefined>} - A promise that resolves to an array of items or undefined if there was an error.
 */
export async function fetchItems(): Promise<readonly ItemData[] | undefined> {
    try {
        const response: Response = await fetch(`${API_BASE_URL}/static/items`);
        const resp = await response.json() as FetchItemsResponse;
        return resp.items;
    } catch (error) {
        console.error('fetchItems: There was a problem with the fetch operation:', error);
        return undefined;
    }
}