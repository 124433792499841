import React from "react";
import {SeachBar} from "./SeachBar";
import {Champion} from "../../api/Types";
import Box from "@mui/material/Box";
import {ChampionStatistics} from "./ChampionStatistics";
import lir_banner from "../../assets/images/lir_banner.png";
import {Grid, Link, Paper, Typography} from "@mui/material";
import {ConfidenceIndicator} from "./ConfidenceIndicator";
import {getSampleMatchCount} from "../../api/Matches";
import {ToolBarMaxWidth} from "../Toolbar/ToolBar";
import {DiscordLink} from "../Contact";

const Information = () => {
    const discordColor = "#7289da";
    const [sampleSize, setSampleSize] = React.useState<number>(0);
    React.useEffect(() => {

        getSampleMatchCount().then((sampleSize: number | undefined) => {
            setSampleSize(sampleSize ?? 0);
        });
    }, [setSampleSize]);
    return (
        <Box sx={{flexGrow: 1, display: 'flex'}}>
            <Grid container spacing={2} sx={{
                flexGrow: 1,
                marginTop: {xs: 3, sm: 6.25, xl: 7},
                maxWidth: `calc(${ToolBarMaxWidth} - 20vh)`,
            }}>
                <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Paper variant={"outlined"} sx={{
                        padding: "2rem",
                        flexGrow: 1,
                    }}>
                        <Typography variant="h4" component="h1">League Item Recommender</Typography>
                        <p>
                            Step into the realm of the League Item Recommender, where your journey to League of Legends
                            supremacy begins with a dash of fun and a sprinkle of strategy. Tailor-made to amplify your
                            in-game prowess, our tool offers bespoke item recommendations that align with your unique
                            combat style. Yet, every adventure has its trials: currently, our map is charted only for
                            the EUW region, and as you navigate this preview version, expect the unexpected, for the
                            landscape may shift as we evolve together.
                        </p>
                        <Typography variant="body1" component="p">
                            Your voice is the catalyst for our quest towards excellence. We eagerly invite feedback of
                            all hues—be it a critique of the UX/UI, feature enhancements, or any innovative ideas you
                            wish to share. Your insights not only guide our journey but may also unlock the gates to an
                            exclusive realm: outstanding contributors could be knighted&nbsp;
                            <Typography variant="body1" component="div"
                                        sx={{
                                            background: 'linear-gradient(120deg, #ff9900, #cc3300)',
                                            WebkitBackgroundClip: 'text',
                                            color: 'transparent',
                                            display: 'inline',
                                        }}>
                                with a VIP account,
                            </Typography>
                            &nbsp;granting
                            early and complimentary access to the treasure trove of premium features on the horizon.
                        </Typography>
                        <p>
                            Join our <Link target={"_blank"} color={discordColor} underline={"hover"}
                                           href={DiscordLink}>Discord battalion</Link>,
                            where together we'll conquer the challenges, celebrate the
                            victories, and shape the future of gaming glory. Your saga of influence begins now—let's
                            make the League Item Recommender not just a tool, but a legend. 🌟🎮
                        </p>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Paper variant={"outlined"} sx={{
                        padding: "2rem",
                        flexGrow: 1,
                    }}>
                        <ConfidenceIndicator sampleSize={sampleSize} title="Confidence indicator"/>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

export function LandingPage() {
    const [selectedChampion, setSelectedChampion] = React.useState<Champion | null>(null);
    const [positionSelected, setPositionSelected] = React.useState<string>("");

    return (
        <Box sx={{overflowX: 'hidden'}}>
            {selectedChampion && <Box sx={{
                top: 0,
                left: 0,
                width: '100%',
                backgroundImage: `url(${selectedChampion.splashArtURL})`,
                backgroundSize: '100% auto',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                overflowX: 'hidden',
            }}
            />}
            {!selectedChampion &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    maxHeight: '35vh',
                    mt: '2rem',
                }}>
                    <img style={{
                        maxWidth: '100%',
                        maxHeight: '35vh',
                        width: 'auto',
                        height: 'auto',
                    }} src={lir_banner} alt={"League Item Recommender main banner"}/>
                </Box>
            }
            <Box sx={{
                mb: {xs: 3, sm: 6.25, xl: 7},
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                height: 'auto'
            }}>
                <SeachBar onChampionSelected={(champion: Champion | null) => {
                    if (champion) {
                        setSelectedChampion(champion);
                    } else {
                        setSelectedChampion(null);
                    }
                }} onPositionSelected={(position: string) => {
                    setPositionSelected(position);
                }}/>
                {selectedChampion ? <ChampionStatistics champion={selectedChampion} position={positionSelected}/> :
                    <Information/>}
            </Box>
        </Box>
    )
}