import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline, Theme} from "@mui/material";
import {darkTheme} from "./view/utils/Theme";
import {LandingPage} from "./view/LandingPage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ResponsiveAppBar from "./view/Toolbar/ToolBar";
import {AboutPage} from "./view/About";
import {ContactPage} from "./view/Contact";
import {RatesPage} from "./view/Rates";
import {SummonerPage} from "./view/Summoner";

export interface SetTheme {
    setCurrentTheme: (theme: Theme) => void;
}

const App: React.FC = () => {
    const [currentTheme, setCurrentTheme] = React.useState(darkTheme);
    return (
        <BrowserRouter>
            <ThemeProvider theme={currentTheme}>
                <CssBaseline/>
                <ResponsiveAppBar setCurrentTheme={setCurrentTheme}/>
                <Routes>
                    <Route path="/" element={<LandingPage/>}/>
                    <Route path="*" element={<LandingPage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/rates/*" element={<RatesPage/>}/>
                    <Route path="/summoner/*" element={<SummonerPage/>}/>
                </Routes>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;