export enum Queue {
    RANKED_SOLO_5x5 = "RANKED_SOLO_5x5",
    RANKED_FLEX_SR = "RANKED_FLEX_SR",
    RANKED_FLEX_TT = "RANKED_FLEX_TT",
}

export const AvailableQueues = [
    "RANKED_SOLO_5x5",
    "RANKED_FLEX_SR",
];

export const QueuesNames = new Map<string, string>([
    ["RANKED_SOLO_5x5", "Ranked 5x5 solo queue"],
    ["RANKED_FLEX_SR", "Ranked 5x5 flex queue"],
    ["RANKED_FLEX_TT", "Ranked 3x3 flex queue"],
]);
