import React from 'react';
import Box from "@mui/material/Box";
import {getApiVersion} from "../../api/Version";
import Typography from "@mui/material/Typography";

type ConfidenceIndicatorProps = {
    sampleSize?: number;
    title: string;
};

const colorConfidence: string[] = ['grey', 'red', 'yellow', 'green', 'blue'];
const textConfidence: string[] = ['Low, not enough data', 'Confidence low', 'Confidence medium', 'Confidence high', 'Confidence very high'];
const explainText: string[] = [
    'The sample size is too low to have a reliable win rate.',
    'The sample size is low, the win rates are not reliable.',
    'The sample size is medium, the win rates are reliable.',
    'The sample size is high, the win rates are reliable.',
    'The sample size is very high, the win rates are reliable.',
];

export const ConfidenceIndicator: React.FC<ConfidenceIndicatorProps> = ({sampleSize, title}) => {
    const getConfidenceIndex = (sampleSize: number | undefined): number => {
        if (sampleSize === undefined || sampleSize <= 500) {
            return 0;
        } else if (sampleSize <= 5000) {
            return 1;
        } else if (sampleSize <= 50000) {
            return 2;
        } else if (sampleSize <= 500000) {
            return 3;
        } else {
            return 4;
        }
    };
    const confidenceIndex = getConfidenceIndex(sampleSize);
    const [version, setVersion] = React.useState<string>("unknown");
    React.useEffect(() => {
        getApiVersion().then((version: string | undefined) => {
            setVersion(version ?? "unknown");
        }).finally(() => {
            if (version === undefined || version === "") {
                setVersion("unknown");
            }
        });
    }, []);

    const circleStyle: React.CSSProperties = {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        textAlign: "center",
        backgroundColor: colorConfidence[confidenceIndex],
    };

    const flexBoxStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingBottom: '1rem',
        paddingTop: '1rem',
    };

    const flexItemStyle: React.CSSProperties = {
        width: '50%',
        textAlign: "left",
        boxSizing: "border-box",
    };

    return (
        <Box>
            {title !== "" ? <Typography variant={"h4"}>{title}</Typography> : null}
            <div style={flexBoxStyle}>
                <div style={flexItemStyle}>{textConfidence[confidenceIndex]}</div>
                <div style={flexItemStyle}>
                    <div style={circleStyle}></div>
                </div>
                <div style={flexItemStyle}> Sample size</div>
                <div style={flexItemStyle}> {sampleSize} </div>
            </div>
            <Box>
                {explainText[confidenceIndex]}
                <br/>
                The sample size represent the number of game crawled. The higher the number, the better is it.
            </Box>
            <p style={{textAlign: 'end'}}>Game version: <strong>{version}</strong></p>
        </Box>
    )
};

