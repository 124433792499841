import React from "react";
import {Divider, styled} from "@mui/material";

interface StyledDividerProps {
    children?: React.ReactNode;
    variant?: "fullWidth" | "inset" | "middle";
    orientation?: "horizontal" | "vertical";
}

export const MainColorDivider = styled((props: StyledDividerProps) => <Divider {...props} />)(
    ({theme}) => ({
            backgroundColor: theme.palette.primary.main,
            marginBottom: "2rem"
        }
    )
);