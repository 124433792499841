import {Grid, Stack, Typography} from "@mui/material";
import {IconWithTooltip} from "./utils";
import {Groups3, MilitaryTech, Visibility} from "@mui/icons-material";
import React from "react";
import {Game, Participants} from "../../../api/Summoner";

export interface DisplayGameDataProps {
    authorData: Participants;
    game: Game;
}

export const DisplayGameData: React.FC<DisplayGameDataProps> = ({authorData, game}) => {
    return (
        <Stack
            justifyContent="center"
            alignItems="center">
            <Typography variant="h5">
                <strong>{authorData.kills} / {authorData.deaths} / {authorData.assists}</strong>
            </Typography>
            <br/>
            <Grid container spacing={0.5}
                  justifyContent="center"
                  alignItems="center">
                <Grid item xs={6}>
                    {IconWithTooltip(
                        Number((authorData.kills + authorData.assists) / authorData.deaths).toFixed(2),
                        "KDA: (Kills + Assists) / Deaths",
                        MilitaryTech)}
                </Grid>
                <Grid item xs={6}>
                    {IconWithTooltip(
                        `${authorData.visionScore} • ${Number(authorData.visionScore / (game.metadata.gameDuration / 60)).toFixed(2)}`,
                        "Vision score • VS / Minute",
                        Visibility)}
                </Grid>
                <Grid item xs={6}>
                    {IconWithTooltip(
                        `${authorData.minionsKilled + authorData.monstersKilled} • ${Number((authorData.minionsKilled + authorData.monstersKilled) / (game.metadata.gameDuration / 60)).toFixed(2)}`,
                        "CS • CS / Minute",
                        Groups3)}
                </Grid>
                <Grid item xs={6}>
                    {IconWithTooltip(
                        authorData.deaths.toString(), "Kill participation",
                        Groups3)}
                </Grid>
            </Grid>
        </Stack>
    )
}