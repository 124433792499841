import {API_BASE_URL, Position} from './Constants';
import {getApiVersion} from "./Version";
import {Champion, ChampionWinRate} from "./Types";

/**
 * ChampionsResponse is an interface that represents the structure of the response from the fetchChampions API call.
 * It includes an array of champions, each champion following the structure defined by the Champion interface.
 *
 * @interface
 * @property {Champion[]} champions - An array of champions.
 */
interface ChampionsResponse {
    champions: Champion[];
}

/**
 * fetchChampions is an asynchronous function that fetches the list of champions from the API.
 * It sends a GET request to the '/champions/list' endpoint and returns the champions received in the response.
 * If there is an error during the fetch operation, it logs the error and returns undefined.
 *
 * @async
 * @function
 * @returns {Promise<Champion[]|undefined>} - A promise that resolves to an array of champions or undefined if there was an error.
 */
export async function fetchChampions(): Promise<Champion[] | undefined> {
    try {
        const response: Response = await fetch(`${API_BASE_URL}/static/champions`);
        const resp: ChampionsResponse = await response.json() as ChampionsResponse;
        return resp.champions;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return undefined;
    }
}

/**
 * ChampionsWinRateResponse is an interface that represents the structure of the response from the getBestChampionsWinRate and getWorstChampionsWinRate API calls.
 * It includes an array of champions' win rates, each following the structure defined by the ChampionWinRate interface.
 *
 * @interface
 * @property {ChampionWinRate[]} champions - An array of champions' win rates.
 */
interface ChampionsWinRateResponse {
    champions: ChampionWinRate[];
}

/**
 * getBestChampionsWinRate is an asynchronous function that fetches the best champions' win rates from the API.
 * It sends a GET request to the '/champions/{apiVersion}/winrate/best' endpoint and returns the champions' win rates received in the response.
 * If there is an error during the fetch operation, it logs the error and returns undefined.
 *
 * @async
 * @function
 * @returns {Promise<ChampionWinRate[]|undefined>} - A promise that resolves to an array of champions' win rates or undefined if there was an error.
 */
export async function getBestChampionsWinRate(position: string = Position.All): Promise<ChampionWinRate[] | undefined> {
    try {
        const response: Response = await fetch(`${API_BASE_URL}/champions/${await getApiVersion()}/winrate/best?position=${position}`);
        const resp: ChampionsWinRateResponse = await response.json() as ChampionsWinRateResponse;
        return resp.champions;
    } catch (error) {
        console.error('getBestChampionsWinRate: There was a problem with the fetch operation:', error);
        return undefined;
    }
}

/**
 * getWorstChampionsWinRate is an asynchronous function that fetches the worst champions' win rates from the API.
 * It sends a GET request to the '/champions/{apiVersion}/winrate/worst' endpoint and returns the champions' win rates received in the response.
 * If there is an error during the fetch operation, it logs the error and returns undefined.
 *
 * @async
 * @function
 * @returns {Promise<ChampionWinRate[]|undefined>} - A promise that resolves to an array of champions' win rates or undefined if there was an error.
 */
export async function getWorstChampionsWinRate(position: string = Position.All): Promise<ChampionWinRate[] | undefined> {
    try {
        const response: Response = await fetch(`${API_BASE_URL}/champions/${await getApiVersion()}/winrate/worst?position=${position}`);
        const resp: ChampionsWinRateResponse = await response.json() as ChampionsWinRateResponse;
        return resp.champions;
    } catch (error) {
        console.error('getWorstChampionsWinRate: There was a problem with the fetch operation:', error);
        return undefined;
    }
}

/**
 * ItemWinRate is an interface that represents the structure of an item's win rate in the application.
 * It includes the item's id, win rate, and the total number of games.
 *
 * @interface
 * @property {number} itemID - The unique identifier of the item.
 * @property {number} winRate - The win rate of the item.
 * @property {number} totalGames - The total number of games played with the item.
 */
export interface ItemWinRate {
    itemID: number;
    winRate: number;
    totalGames: number;
}

/**
 * BestBuild is an interface that represents the structure of the best build for a champion in a specific position in the application.
 * It includes arrays of items, boots, trinkets, and starters, each following the structure defined by the ItemWinRate interface.
 *
 * @interface
 * @property {ItemWinRate[]} items - An array of items.
 * @property {ItemWinRate[]} boots - An array of boots.
 * @property {ItemWinRate[]} trinket - An array of trinkets.
 * @property {ItemWinRate[]} starter - An array of starters.
 */
export interface BestBuild {
    items: ItemWinRate[];
    boots: ItemWinRate[];
    trinket: ItemWinRate[];
    starter: ItemWinRate[];
}

/**
 * getBestBuild is an asynchronous function that fetches the best build for a champion in a specific position from the API.
 * It sends a GET request to the '/champions/{apiVersion}/build' endpoint with the champion's id and position as query parameters and returns the best build received in the response.
 * If there is an error during the fetch operation, it logs the error and returns undefined.
 *
 * @async
 * @function
 * @param {number} championID - The unique identifier of the champion.
 * @param {string} position - The position of the champion.
 * @returns {Promise<BestBuild|undefined>} - A promise that resolves to the best build for the champion in the specified position or undefined if there was an error.
 */
export async function getBestBuild(championID: number, position: string): Promise<BestBuild | undefined> {
    try {
        const response: Response = await fetch(`${API_BASE_URL}/champions/${await getApiVersion()}/build?id=${championID}&position=${position}`);
        if (response.status !== 200) {
            console.log('getBestBuild: There was a problem with the fetch operation:', response);
            console.log(await response.text());
            return undefined;
        }
        return await response.json() as BestBuild;
    } catch (error) {
        console.error('getBestBuild: There was a problem with the fetch operation:', error);
        return undefined;
    }
}

export interface ChampionBanRate {
    championID: number;
    banRate: number;
    totalBans: number;
}

interface BanRateResponse {
    champions: ChampionBanRate[];
}

export async function getBanRateLadder(order: String = 'desc'): Promise<ChampionBanRate[] | undefined> {
    try {
        const response: Response = await fetch(`${API_BASE_URL}/champions/${await getApiVersion()}/ban-rate?order=${order}`);
        const resp: BanRateResponse = await response.json() as BanRateResponse;
        return resp.champions;
    } catch (error) {
        console.error('getBanRateLadder: There was a problem with the fetch operation:', error);
        return undefined;
    }
}