import Bronze from './Bronze.png';
import Challenger from './Challenger.png';
import Diamond from './Diamond.png';
import Gold from './Gold.png';
import Grandmaster from './Grandmaster.png';
import Iron from './Iron.png';
import Master from './Master.png';
import Platinum from './Platinum.png';
import Silver from './Silver.png';
import Emerald from './Emerald.png';
import Unranked from './Unranked.webp';
import {Rank} from "../../view/utils/Summoner/Rank";

// Function to get an image based on rank
export function getImageFromRank(rank: string | undefined): string {
    const rankImages: { [key: string]: string } = {
        [Rank.Bronze]: Bronze,
        [Rank.Challenger]: Challenger,
        [Rank.Diamond]: Diamond,
        [Rank.Gold]: Gold,
        [Rank.Grandmaster]: Grandmaster,
        [Rank.Iron]: Iron,
        [Rank.Master]: Master,
        [Rank.Platinum]: Platinum,
        [Rank.Silver]: Silver,
        [Rank.Emerald]: Emerald,
        [Rank.Unranked]: Unranked
    };

    return rank ? rankImages[rank] : Unranked;
}
