import React from "react";
import {Chip, Divider, Stack, styled, Tooltip, Zoom} from "@mui/material";

export const IconWithTooltip = (data: string, tooltipTitle: string, icon: React.ElementType) => {
    return (
        <Tooltip title={tooltipTitle} placement="left-start" arrow TransitionComponent={Zoom}
                 slotProps={{
                     popper: {
                         modifiers: [
                             {
                                 name: 'offset',
                                 options: {
                                     offset: [0, -30],
                                 },
                             },
                         ],
                     },
                 }}>
            <Stack direction={"row"} spacing={2}
                   justifyContent="center"
                   alignItems="center">
                {/*{React.createElement(icon)}*/}
                {/*<Typography variant="subtitle1">{data}</Typography>*/}
                <Chip avatar={React.createElement(icon)} label={data}/>
            </Stack>
        </Tooltip>
    )
}

export const CardDivider = styled(Divider)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#000" : "#fff",
}));