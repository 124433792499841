import React from "react";
import {FormControl, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import * as positionIcons from '../../assets/images/position/index';
import {ImageIcon} from "../utils/Image";


interface RoleWithIcon {
    name: string;
    iconPath: string;
}

const roleImages: RoleWithIcon[] = [
    {name: 'All', iconPath: positionIcons.allIcon},
    {name: 'Top', iconPath: positionIcons.topIcon},
    {name: 'Jungle', iconPath: positionIcons.jungleIcon},
    {name: 'Mid', iconPath: positionIcons.midIcon},
    {name: 'Bot', iconPath: positionIcons.botIcon},
    {name: 'Support', iconPath: positionIcons.supportIcon},
];

export const DefaultPosition: string = roleImages[0].name;

interface PositionSelectorProps {
    onPositionSelected: (position: string) => void;
}

export const PositionSelector: React.FC<PositionSelectorProps> = ({onPositionSelected}) => {
    const [selectedItem, setSelectedItem] = React.useState<string>(roleImages[0].name);

    const handleChange = (event: SelectChangeEvent): void => {
        setSelectedItem(event.target.value);
    };

    React.useEffect(() => {
        onPositionSelected(selectedItem);
    }, [onPositionSelected, selectedItem])

    return (
        <FormControl fullWidth>
            <Select
                value={selectedItem}
                onChange={handleChange}
            >
                {roleImages.map((icon: RoleWithIcon) => (
                    <MenuItem value={icon.name} key={icon.name}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "start"
                        }}>
                            <ImageIcon iconPath={icon.iconPath} />
                            {icon.name}
                        </div>
                    </MenuItem>

                ))}
            </Select>
        </FormControl>
    );
};