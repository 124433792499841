import {Button, Card, Grid, Stack, Tab, Typography} from "@mui/material";
import React from "react";
import {useLocation} from "react-router-dom";
import {SxDefaultPageStyle} from "../utils/Theme";
import {fetchSummoner, SummonerResponse} from "../../api/Summoner";
import {ResetTitle} from "../utils/Title";
import {SummonerPageData} from "./Data";
import {QueueRanks} from "./QueuesRank";
import {SummonerIcon} from "./SummonerIcon";
import {GamesHistory} from "./GamesHistory/GamesHistory";
import {AvailableQueues, Queue, QueuesNames} from "../utils/Summoner/Queue";
import TabContext from "@mui/lab/TabContext";
import {TabList} from "@mui/lab";
import {ChampionsProvider} from "../../context/Champion";
import {ItemsProvider} from "../../context/Items";
import {SummonerSpellsProvider} from "../../context/SummonerSpells";


export const SummonerPage = () => {
    const location = useLocation();
    const optionSelected = location.state.item as SummonerPageData || null;
    const [summoner, setSummoner] = React.useState<SummonerPageData | null>(null);
    const [value, setValue] = React.useState('0');

    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (!optionSelected) {
            console.info("No option selected, retrieving it from URL query parameters.");
            const name = queryParams.get("name");
            const tagLine = queryParams.get("tagline");
            const region = queryParams.get("region");
            if (name !== null && tagLine !== null && region !== null) {
                console.info("Retrieved summoner from URL query parameters:", name, tagLine, region);
                fetchSummoner(name, tagLine).then((summoner: SummonerResponse | undefined) => {
                    if (summoner !== undefined && summoner.name !== undefined && summoner.tagLine !== undefined) {
                        setSummoner(summoner);
                        ResetTitle(summoner.name + " - League Item Recommender");
                    } else {
                        console.error("Error fetching summoner", summoner);
                        // TODO: Show error message
                    }
                });
            }
        } else {
            setSummoner({...optionSelected});
            console.info("Option selected:", optionSelected);
            ResetTitle(optionSelected.name + " - League Item Recommender");
        }
    }, [location.search, optionSelected]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }

    return (
        <Stack sx={{...SxDefaultPageStyle, maxWidth: '120rem'}} m={0}>
            <Card variant="outlined">
                <Stack direction="row" spacing={4} p={2}>
                    <SummonerIcon summoner={summoner}/>
                    <Stack>
                        <Stack direction="row" alignItems="flex-end">
                            <Typography variant="h2" component="h1">
                                {summoner?.name}
                            </Typography>
                            <Typography variant="h4">
                                #{summoner?.tagLine}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start"
                               sx={{width: '100%'}}>
                            <Button variant="outlined" color="primary" sx={{height: "100%"}}
                                    disabled={summoner?.beingFetch}>
                                Last
                                update: {summoner?.lastUpdatedAt && !isNaN(summoner?.lastUpdatedAt.getTime()) ? summoner.lastUpdatedAt.toLocaleString() : "Never"}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
            <Grid container columnSpacing={4} mt={2} justifyContent={"space-between"}>
                <Grid item xs={3}>
                    <QueueRanks summoner={summoner}/>
                </Grid>
                <Grid item xs={9}>
                    <Stack spacing={2}>
                        <Card variant={"outlined"}>
                            <TabContext value={value}>
                                <TabList onChange={handleTabChange}>
                                    {AvailableQueues.map((queue, index) => <Tab
                                        label={QueuesNames.get(queue) ?? "Unknown queue"} value={String(index)}
                                        key={index}/>)}
                                </TabList>
                            </TabContext>
                        </Card>
                        <SummonerSpellsProvider>
                            <ChampionsProvider>
                                <ItemsProvider>
                                    <GamesHistory summoner={summoner}
                                                  queueSelected={AvailableQueues[Number(value)] as Queue}/>
                                </ItemsProvider>
                            </ChampionsProvider>
                        </SummonerSpellsProvider>
                    </Stack>
                </Grid>
            </Grid>
        </Stack>
    );
}