import React from "react";
import {Box, Divider, List} from "@mui/material";
import {ChampionBanRate} from "../../api/Champions";
import {ChampionWinRate} from "../../api/Types";
import {ShowCircularProgress} from "../utils/Progress";
import {ShowNotEnoughDataError} from "../utils/Error";

/**
 * Function to display rate with color.
 * @param {number} rate - The rate to be displayed.
 * @param {(rate: number) => (string)} colorFormatter - Function to format color based on rate.
 * @returns {React.ReactNode} - The rate displayed in the color returned by colorFormatter.
 */
export function DisplayRateNodeWithColor(rate: number, colorFormatter: (rate: number) => (string)): React.ReactNode {
    return (
        <span
            style={{
                color: colorFormatter(rate),
                fontWeight: "bold",
            }}
        >
          {rate.toFixed(2)}%
        </span>
    );
}

/**
 * Type for champion data.
 * Can be either ChampionWinRate or ChampionBanRate.
 */
export type ChampionData = ChampionWinRate | ChampionBanRate;

/**
 * Interface for rate properties.
 */
interface RateProps {
    title: React.ReactNode; // Title of the rate.
    rates: readonly ChampionData[]; // Array of champion data.
    listItem: (data: ChampionData) => React.ReactNode; // Function to list items of champion data.
    loading: boolean;
    error: boolean;
    subTitle?: React.ReactNode; // Properties for rate switch, can be null.
}

/**
 * Functional component to display a list of rates with a toggle.
 * @param {RateProps} {rates, title, toggleProps, listItem} - The properties for the rate list.
 * @returns {React.ReactNode} - The rendered rate list with toggle.
 */
export const RateList = React.forwardRef<HTMLDivElement, RateProps>((props, ref) => {
    return (
        <>
            <Box sx={{p: '0.5rem'}}>
                {props.title}
            </Box>
            {props.subTitle ?
                <Box sx={{p: '1rem'}}>
                    {props.subTitle}
                </Box> : null
            }

            <Divider/>
            {props.loading ? <ShowCircularProgress/> : props.error ?
                <ShowNotEnoughDataError ref={ref} label="Not enough data or an error happened"/> :
                <List>
                    {props.rates.map((championRate, index) => (
                        <span key={index} ref={ref}>
                            {props.listItem(championRate)}
                        </span>
                    ))}
                </List>
            }
        </>
    );
});