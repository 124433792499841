import {Stack, styled, Tooltip, TooltipProps, Typography, Zoom} from "@mui/material";
import {formatDistance} from "date-fns";
import {ImageIcon, ImageIconWithBorder} from "../../utils/Image";
import React, {useContext} from "react";
import {ChampionsContext} from "../../../context/Champion";
import {SummonerSpellsContext} from "../../../context/SummonerSpells";
import {Game, Participants} from "../../../api/Summoner";
import Box from "@mui/material/Box";

const TooltipWithArrow = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} arrow TransitionComponent={Zoom}/>
))``;

export interface ChampionHeaderProps {
    authorData: Participants;
    game: Game;
}

export const ChampionHeader: React.FC<ChampionHeaderProps> = ({authorData, game}) => {
    const {getChampionById} = useContext(ChampionsContext);
    const {getSummonerSpellById} = useContext(SummonerSpellsContext);

    return (
        <Stack alignItems="center" spacing={0.5}>
            <TooltipWithArrow title={new Date(game.metadata.gameStartTimestamp).toDateString()}
                              placement="top"
                              slotProps={{
                                  popper: {
                                      modifiers: [
                                          {
                                              name: 'offset',
                                              options: {
                                                  offset: [0, -15],
                                              },
                                          },
                                      ],
                                  },
                              }}>
                <Typography variant="body1">
                    {formatDistance(new Date(game.metadata.gameStartTimestamp), new Date(), {addSuffix: true})}
                </Typography>
            </TooltipWithArrow>
            <ImageIcon iconPath={getChampionById(authorData.championID)?.iconURL ?? undefined}
                       alt="champion"
                       size={60}/>
            <Stack direction="row" spacing={0.5}>
                <Tooltip title={getSummonerSpellById(authorData.summonerSpell1)?.name} arrow
                         placement="top">
                    <Box>
                        <ImageIconWithBorder
                            iconPath={getSummonerSpellById(authorData.summonerSpell1)?.iconURL ?? undefined}
                            alt="spell1"
                            size={24}
                        />
                    </Box>
                </Tooltip>

                <TooltipWithArrow title={getSummonerSpellById(authorData.summonerSpell2)?.name}
                                  placement="top">
                    <Box>
                        <ImageIconWithBorder
                            iconPath={getSummonerSpellById(authorData.summonerSpell2)?.iconURL ?? undefined}
                            alt="spell2"
                            size={24}
                        />
                    </Box>
                </TooltipWithArrow>
            </Stack>
            {/*<Typography variant="button">{game.metadata.authorWon ? "Victory" : "Defeat"}</Typography>*/}
        </Stack>
    )
}