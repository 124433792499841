export var API_BASE_URL: string;

if (process.env.NODE_ENV === 'development') {
    API_BASE_URL = 'http://localhost:80'
} else {
}
API_BASE_URL = 'https://api.league-item-recommender.com'

/**
 * Enum for Positions
 * @enum {string}
 * @property {string} All - Represents all positions
 * @property {string} Top - Represents the top position
 * @property {string} Jungle - Represents the jungle position
 * @property {string} Middle - Represents the middle position
 * @property {string} Bottom - Represents the bottom position
 * @property {string} Support - Represents the support position
 */
export enum Position {
    All = "All",
    Top = "Top",
    Jungle = "Jungle",
    Middle = "Middle",
    Bottom = "Bottom",
    Support = "Support"
}