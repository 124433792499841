import {API_BASE_URL} from "./Constants";
import {getApiVersion} from "./Version";


/**
 * MatchesSampleResponse is an interface that represents the structure of the response from the getMatchesSample API call.
 * It includes the count of matches.
 *
 * @interface
 * @property {number} count - The count of matches.
 */
interface MatchesSampleResponse {
    count: number;
}

/**
 * getMatchesSample is an asynchronous function that fetches the sample of matches from the API.
 * It sends a GET request to the '/matches/{apiVersion}/sample' endpoint and returns the count of matches received in the response.
 * If there is an error during the fetch operation, it logs the error and returns undefined.
 *
 * @async
 * @function
 * @returns {Promise<number|undefined>} - A promise that resolves to the count of matches or undefined if there was an error.
 */
export async function getSampleMatchCount(): Promise<number | undefined> {
    try {
        const response: Response = await fetch(`${API_BASE_URL}/matches/${await getApiVersion()}/sample`);
        const resp: MatchesSampleResponse = await response.json() as MatchesSampleResponse;
        return resp.count;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return undefined;
    }
}